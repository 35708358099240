import { CSSProperties } from 'react';

export const ccBrands = ['VISA', 'MASTERCARD', 'AMEX', 'DISCOVER', 'FSAHSA'];
export type CreditCardVariant = 'VISA' | 'MASTER CARD' | 'DISCOVER' | 'AMERICAN EXPRESS' | 'FSAHSA' | string;

export interface CreditcardProps {
    variant?: CreditCardVariant;
    style?: CSSProperties;
    className?: string;
    isSmallIcon?: boolean;
}
