import './tooltip.styles.scss';

interface TooltipProps extends React.HTMLAttributes<HTMLDivElement> {
    tip: string;
    position?: 'top' | 'bottom' | 'left' | 'right';
    minWidth?: number;
}

export const Tooltip = ({ children, tip, position = 'top', minWidth = 300 }: TooltipProps) => {
    return (
        <div className={`tooltip-component tooltip-component--${position}`}>
            <span className="tooltip-component__tip" style={{ minWidth: minWidth }}>
                {tip}
            </span>
            {children}
        </div>
    );
};
