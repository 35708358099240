import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from 'ui-kit/button/button';

import { membershipRegistrationClearPromoCodeRoutine } from 'state/membership-registration/membership-registration.routines';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import PromoCodeModalContent from './promo-code-modal-content';
import './promo-code-modal.styles.scss';

interface PromoCodeModalProps {
    onSubmit: (value: string) => void;
}

const PromoCodeModal = ({ onSubmit }: PromoCodeModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        dispatch(closeModalComponent());
        dispatch(membershipRegistrationClearPromoCodeRoutine.trigger());
    };

    const handleOpenModal = () => {
        dispatch(
            openModalComponent({
                title: t('forms.membership.paymentMethod.promoCodeModal.title'),
                hasCustomContent: true,
                isCloseable: true,
                hasDefaultFooter: false,
                customDialogClassName: 'promo-code-modal-container',
                variation: 'large',
                isCentered: true,
                content: <PromoCodeModalContent onContinue={onSubmit} onClose={handleCloseModal} />,
                onClose: handleCloseModal
            })
        );
    };

    return (
        <Button
            className="promo-code-modal__button"
            label={t('membership.paymentMethod.promoCode.openBtn')}
            type="button"
            variant="text-blue"
            plusIcon
            IconType="fourth"
            onClick={handleOpenModal}
        />
    );
};

export default PromoCodeModal;
