import { useFormik } from 'formik';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TextInputComponent from 'ui-kit-v2/text-input/text-input';
import * as yup from 'yup';

import ToastBox from 'ui-kit/toast-box/toast-box';

import { ModalComponentContent, ModalComponentFooter } from 'components/modal/modal.component';

import {
    membershipRegistrationLoadingSelector,
    membershipRegistrationValidPromoCodeSelector
} from 'state/membership-registration/membership-registration.selectors';

import './promo-code-modal.styles.scss';

const PROMO_CODE_SCHEMA = (t: TFunction) =>
    yup.object().shape({
        promoCode: yup.string().required(t('forms.membership.paymentMethod.promoCodeModal.errorCopy'))
    });

interface PromoCodeModalContentProps {
    onContinue: (value: string) => void;
    onClose: () => void;
}

const PromoCodeModalContent = ({ onContinue, onClose }: PromoCodeModalContentProps) => {
    const { t } = useTranslation();

    const promoCodeStatus = useSelector(membershipRegistrationValidPromoCodeSelector);
    const isBusy = useSelector(membershipRegistrationLoadingSelector);

    const isPromoCodeInvalid = promoCodeStatus === false && !isBusy;

    const onSubmit = () => {
        if (!formik.isValid) {
            return;
        }
        onContinue(formik.values.promoCode);
    };

    const formik = useFormik({
        initialValues: {
            promoCode: ''
        },
        validationSchema: PROMO_CODE_SCHEMA(t),
        onSubmit: onSubmit
    });

    return (
        <ModalComponentContent>
            <form
                id="promo-code-modal"
                autoComplete="off"
                noValidate
                onSubmit={(e: React.FormEvent) => e.preventDefault()}
            >
                <div className="promo-code-modal__content">
                    <TextInputComponent
                        {...formik.getFieldProps('promoCode')}
                        label={t('forms.membership.paymentMethod.promoCodeModal.inputCopy')}
                    />
                    {isPromoCodeInvalid && (
                        <div className="promo-code-modal__error-container">
                            <ToastBox icon="warning" variant="warning">
                                <p className="promo-code-modal__error-message">
                                    {t('forms.membership.paymentMethod.promoCodeModal.errorCopy')}
                                </p>
                            </ToastBox>
                        </div>
                    )}
                </div>
            </form>
            <ModalComponentFooter
                continueButtonLabel={t('forms.membership.paymentMethod.promoCodeModal.continueBtn')}
                cancelButtonLabel={t('forms.membership.paymentMethod.promoCodeModal.cancelBtn')}
                isCTABusy={isBusy}
                onCancel={onClose}
                onContinue={onSubmit}
            />
        </ModalComponentContent>
    );
};

export default PromoCodeModalContent;
